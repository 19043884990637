import React from 'react';
import DataGrid, { Column, Grouping, GroupPanel, SearchPanel, Scrolling } from 'devextreme-react/data-grid';

import WorkDate from '../../Utils/WorkDate';

import './ViewList.override.scss';
import styles from './ViewList.module.scss'

class ViewList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowKey: this.props.date,
      visibleDate: this.props.date.DE
    }

    this.dataCol = this.dataCol.bind(this);
    this.contentReady = this.contentReady.bind(this);
  }

  dataCol(e) {
    if (e.data.link) {
      return <span 
                className={styles.link}
                onClick={() => {
                  this.props.setAppState({chartName: e.data.name});
                  this.props.history.push('ViewChart?chartId=' + e.data.linkId)
                }}
              > 
                { e.data.name } 
              </span>
    } else {
      return <span> { e.data.name } </span>
    }    
  }

  contentReady(e) {
    const visibleRows = e.component.getVisibleRows();    
    const visibleDate = visibleRows.find(e => e.rowType === 'data')?.key
    if (visibleDate && visibleDate !== this.state.visibleDate){
      this.setState({visibleDate: visibleDate})
    }

  }

  render() {
    return (
        <DataGrid
          keyExpr='date'
          ref={this.props.gridRef}
          height={this.props.contentRef.current?.offsetHeight}
          dataSource={this.props.dataSource}
          selectedRowKeys={[this.props.date.rowKey]}
          allowColumnReordering={false}
          rowAlternationEnabled={false}
          showBorders={true}
          remoteOperations={true}
          filter
          onInitialized={e => e.component.navigateToRow(this.props.date.rowKey)}
          onContentReady={this.contentReady}
        >
          <Scrolling mode='virtual' />
          <SearchPanel visible={false} text={this.props.searchText} /> 
          <GroupPanel visible={false} />          
          <Grouping autoExpandAll={true} allowCollapsing={true}/>

          <Column dataField="Datum" caption="Datum" groupIndex={0} sortOrder="desc" dataType="date" format={{ formatter: (d) => new WorkDate(d).DE }} />
          <Column dataField="Thema" caption="Thema" dataType="string" groupIndex={1} />
          <Column dataField="time" caption="Datum:" allowSorting={false} dataType="string"  width={80} />
          <Column dataField="name" caption={this.state.visibleDate} allowSorting={false} dataType="string" cellRender={this.dataCol} />
        </DataGrid>
        
    );
  }
}

export default ViewList; 