import React from 'react';
import  {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Export,
  Legend,
  Pane,
  Title,
  ZoomAndPan,
  CommonSeriesSettings,
  AdaptiveLayout,
} from 'devextreme-react/chart';
// import * as C from 'devextreme-react/chart';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

// import styles from './ViewChart.module.scss';

class ViewChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
    
    this.dataSource = new DataSource({
      store: new CustomStore({
        load: (loadOptions) => {
          return fetch(`${this.props.dburl}/contentData?token=${this.props.token}&id=${this.props.chartId}`)
            .then(response => response.json())
            .then((result) => {
              this.setState({
                ...result
              })
              return result.dataSource;
            });
        }
      }),
      paginate: false
    }); 
  }

  render() {
    // const Lala = C['Export'];    
    return (
      <>
        {/* <h1 className={styles.h1}>{this.props.chartName}</h1> */}
        <Chart 
          dataSource={this.dataSource}
          rotated={this.state?.rotated}
        >
          
          <ZoomAndPan
            argumentAxis="both"
            valueAxis="both"
          />
          <AdaptiveLayout {...this.state?.adaptiveLayout } />
          <ArgumentAxis {...this.state?.argumentAxis}/>
          <ValueAxis {...this.state?.valueAxis}/>
          <Legend {...this.state?.legend} verticalAlignment="bottom" horizontalAlignment="center"/>
          <Pane {...this.state?.panes} />
          <Title {...this.state?.title} />
          <CommonSeriesSettings {...this.state?.commonSeriesSettings}/>
          { 
            Array.isArray(this.state?.series) ? 
            this.state?.series.map((serie, i) => <Series key={`serie-${i}`} {...serie} />) :
            <Series {...this.state?.series}/>
          }
          <Export enabled={true} />     
          <AdaptiveLayout keepLabels={true} />
        </Chart>
      </>   
    );
  }
}

export default ViewChart;