import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import './DbKey.scss';

async function setSessionKeycode(credentials, setDburl) {
  return fetch(`https://techpunk.de/dbkey?code=${credentials.keycode}`, { //
    method: 'GET', //POST
    crossDomain: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(data => 
    data.json()
  )
  .then(jsonData => {
    if (jsonData.url) {
      setDburl(jsonData.url)
    }      
  })
  .catch(err => {
    console.log("Login Fehler: ", err);
  })
}

export default function Login(props) {
  const [keycode, setKeycode] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    await setSessionKeycode({
      keycode
    }, props.setDburl);
  }

  return(
      <div className="login-wrapper">
        <h1>Bitte geben sie Ihren Einrichtugs Code ein. (h2x34u) / (agw1x2)</h1>
        <Form onSubmit={handleSubmit}>
          <label>
            <Form.Control type="text" onChange={e => setKeycode(e.target.value)}/>
          </label>
          <div>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
  )
}