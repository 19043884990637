import React from 'react';
// import { Watch } from  'react-loader-spinner';
import WorkDate from '../../Utils/WorkDate';

// import styles from './ViewSelector.module.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'devextreme/dist/css/dx.light.css';

class ViewSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            View: this.props.Views.ViewList
        }

        this.setLocation = this.setLocation.bind(this);
    }

    componentDidMount() {
        this.setLocation(window.location);
        this.props.history.listen(({action, location}) => {
            this.setLocation(location);
        });
    }

    parseParam(param) {
        try {
            return new WorkDate(param)
        } catch {
            return param
        }
    }

    setLocation(location) {
        const searchParams = new URLSearchParams(location.search)
        const pathname = location.pathname.replace('/', '');
        const keys = searchParams.keys();        
        for (const key of keys) {          
            const value = key === 'date' ? new WorkDate(searchParams.get(key)) : searchParams.get(key)
            this.props.setAppState({
                [key]: value
            })
        }        
        this.setState({
            View: this.props.Views[pathname] ? this.props.Views[pathname] : this.state.View
        },
        () => {
            console.log(this.state.View.viewName)
            this.props.setAppState({viewName: this.state.View.viewName})
        });
    }

    render() {        
        return (
            <div>
                { this.props.dataSource ? <this.state.View {...this.props} /> : this.props.Loading  }
            </div>
        );
      }
}

export default ViewSelector;