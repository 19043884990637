import React from 'react';
import Calendar from 'devextreme-react/calendar';
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";

import WorkDate from '../../Utils/WorkDate';

import './CalendarCell/CalendarCell.override.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'devextreme/dist/css/dx.light.css';
import styles from './ViewCalendar.module.scss';

class ViewCalendar extends React.Component {
    constructor(props) {
        super(props);

        loadMessages(deMessages);
        locale(navigator.language);

        this.calendarValueChanged = this.calendarValueChanged.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.renderCell = this.renderCell.bind(this);
    }

    calendarValueChanged(e) {
        if (e.value && this.props.dates.hasDate(e.value)) {
            this.props.Views.ViewList.show({date: new WorkDate(e.value).toDateString()})
        }
    }

    onOptionChanged(e) {

    }

    renderCell(data, index) {        
        let className = '';
        if (data.view === "month") className = this.props.dates.hasDate(data.date) ? 'hasData' : '';
        if (data.view === "year") className = this.props.dates.hasMonth(data.date) ? 'hasData' : '';
        if (data.view === "decade") className = this.props.dates.hasYear(data.date) ? 'hasData' : '';
        if (data.view === "century") className = this.props.dates.hasYear(data.date) ? 'hasData' : '';        

        return <div className={className}>
                    { data.text }
                </div>
    }

    render() {
        return (
            <Calendar 
                className={styles.calender}
                firstDayOfWeek={1}
                value={new WorkDate(this.props.date)}
                onValueChanged={this.calendarValueChanged}
                onOptionChanged={this.onOptionChanged}
                // cellComponent={(props) => <CalendarCell data={props.data} index={props.index} />}
                cellRender={this.renderCell}
            />
        );
      }
}

export default ViewCalendar;