import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Button from 'react-bootstrap/Button';
import WorkDate from '../../Utils/WorkDate';
import { BsCalendar3, BsList, BsGear, BsSearch, BsX } from 'react-icons/bs';

import styles from './ViewLayout.module.scss';
import profilePic from '../../assets/images/logo.png';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {};

        this.toggleButtons = [
            {value: 'ViewCalendar', icon: <BsCalendar3 />},
            {value: 'ViewList', icon: <BsList />},
            {value: 'Settings', icon: <BsGear />},
        ];

        this.submitSearch = this.submitSearch.bind(this);
    }

    submitSearch(e) {
        e.preventDefault();
        this.props.Views.ViewList.show()
    }

    render() {
        return (
            <Container className={styles.container}>
                {/* https://drive.google.com/drive/folders/1PmHJWVyQZFNggM-_onltDn2DtKLnTHj7?usp=sharing */}
                <Row className={styles.rowHeader}>
                    <Col md={1} sm={1} xs={2}>
                        <img 
                            src={profilePic} 
                            className={styles.profilePic} 
                            alt='profilePic'
                            onClick={() => {
                                this.props.setSearchState('', {date: new WorkDate()});
                                this.props.Views.ViewList.show();
                            }}
                        />
                    </Col>
                    <Col className={styles.colRight}>
                        <Form onSubmit={this.submitSearch}>                    
                            <InputGroup className={styles.inputGroup}>
                                <InputGroup.Text style={{borderRight: 0, background: 'transparent', paddingRight: 0}}>
                                    <BsSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    className={styles.search}
                                    type="text" 
                                    value={this.props.searchText}
                                    name="search" 
                                    id="search" 
                                    placeholder="Suche..." 
                                    onChange={(e) => {
                                        this.props.setSearchState(e.target.value);                              
                                    }}                         
                                />
                                <InputGroup.Text onClick={() => this.props.setSearchState('')} style={{ background: 'transparent'}}>
                                    <BsX />
                                </InputGroup.Text>
                            </InputGroup>
                        </Form>
                    </Col>
                </Row>

                <Row className={styles.rowContent} ref={this.props.contentRef}>
                    <Col>
                        {React.cloneElement(this.props.children, { 
                            ...this.props
                        })}
                    </Col>
                </Row>

                <Row className={styles.rowFooter}>
                    <Col md={12}>
                        <ButtonGroup className={styles.buttonGroup}>                            
                            {this.toggleButtons.map((toggleButton, idx) => {
                                const checked = this.props.viewName === toggleButton.value;
                                return <ToggleButton
                                    key={toggleButton.value}
                                    id={`toggleButton-${idx}`}
                                    type="radio"
                                    variant={checked ? 'warning' : 'outline-warning'}
                                    name="toggleButton"
                                    value={toggleButton.value}
                                    checked={checked}
                                    onChange={ (e) => this.props.Views[toggleButton.value].show(toggleButton.props) }
                                >
                                    {toggleButton.icon}
                                </ToggleButton>
                            })}
                        </ButtonGroup>
                    </Col>               
                </Row>
            </Container>
        )
    }
}

export default Layout;