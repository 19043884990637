import { createBrowserHistory } from 'history';

import ViewCalendar from "../ViewCalendar";
import ViewList from "../ViewList";
import ViewChart from '../ViewChart';
import Settings from '../Settings';

export const history = createBrowserHistory();

const Views = {
        'ViewCalendar': ViewCalendar,
        'ViewList': ViewList,
        'ViewChart': ViewChart,
        'Settings': Settings
}

for (const view in Views) {
    Views[view].viewName = view;
    Views[view].show = (props) => {
        let propString = '';
        for (const prop in props) {
            propString += '&' + prop + '=' + props[prop]
        }
        propString = propString.replace('&', '?');

        history.push(view + propString);
    }
}

export { Views } ;