import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';

function Settings(props) {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Settings</h1>
        </Col>
      </Row>

      <Row>
        <Col>
          Account:
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant='outline-warning' onClick={() => props.sessionState('token').remove()}>Logout</Button>
        </Col>
      </Row>

      {/* <Row>
        <Col>
          Notifications:
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check 
            onClick={() => {
              
              Notification.requestPermission()
              window.location.reload()
            }}
            type="switch"
            checked={Notification.permission === "granted"}
            id="custom-switch"
            label="Check this switch"
          />
        </Col>
      </Row> */}
    </Container>
  );
}

export default Settings;