import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './PublicLayout.module.scss';
import profilePic from '../../assets/images/logo.png';

class PublicLayout extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {

        };
    }

    render() {
        return (
            <Container fluid className={styles.container}>
                <Row>
                    <Col>
                        <img src={profilePic} className={styles.logo} alt='logo' />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.children}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default PublicLayout;