import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {encode as base64_encode} from 'base-64'; //decode as base64_decode, 

import './Login.scss';

async function loginUser(credentials, setToken) {
  console.log("credentials:", credentials);
  return fetch(`${credentials.dburl}/login`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + base64_encode(credentials.username + ":" + credentials.password)
    },
  })
  .then(data => 
    data.json()
  )
  .then(jsonData => {
    if (jsonData.token) {
      setToken(jsonData.token)
    }      
  })
  .catch(err => {
    console.log("Login Fehler: ", err);
  })
}

export default function Login(props) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const dburl = props.dburl;

  const handleSubmit = async e => {
    e.preventDefault();
    await loginUser({
      username,
      password,
      dburl
    }, props.setToken);
  }

  return(
      <div className="login-wrapper">
        <h1>Login</h1>
        <Form onSubmit={handleSubmit}>
          <label>
            <span>Username</span>
            <Form.Control type="text" onChange={e => setUserName(e.target.value)}/>
          </label>
          <label>
            <span>Password</span>
            <Form.Control  type="password" onChange={e => setPassword(e.target.value)}/>
          </label>
          <div>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};