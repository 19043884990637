import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC6hzml5r4sX-gNvnF4Y_z8edYHQHmeGhY",
  authDomain: "cockpit-assistant.firebaseapp.com",
  projectId: "cockpit-assistant",
  storageBucket: "cockpit-assistant.appspot.com",
  messagingSenderId: "413168558263",
  appId: "1:413168558263:web:55bd0d7db8cd880b276d32"
};

export const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

export const getMessageToken = (callback) => {
  getToken(messaging, { 
    vapidKey: 'BB4sLj5HpPRFKxDnFKtO2Y8p5qOTtrF3c3gHqy3qn94yelPewnLSJc3SZiQn-84KCwevpkDLQuesLBCW_7sfbAs'
  }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      console.log('messageToken: ', currentToken)
      if (callback) callback(currentToken);
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // Notification.requestPermission().then(function (permission) {
      //   if (permission === "granted") {
      //     window.location.reload();
      //   } else {
          
      //   }
      // });
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}



onMessage(messaging, (payload) => {
  console.log("client - onMessage: ", payload);
});