import React from 'react';
import { getMessageToken } from './firebase-messaging';
import { Watch } from  'react-loader-spinner';

import { Views, history } from './Components/ViewSelector/views';
import PublicLayout from './Components/PublicLayout';
import ViewLayout from './Components/ViewLayout';
import ViewSelector from './Components/ViewSelector';
import DbKey from './Components/DbKey';
import Login from './Components/Login';
import getDataList from './Utils/dataConnector/getDataList';
import WorkDate from './Utils/WorkDate';
import styles from './App.module.scss';

class App extends React.Component {
    constructor(props) {
        super(props);     

        this.state = {
            date: new WorkDate(),
            viewName: '',
            chartId: '',
            chartName: '',            
            searchText: '',
            dataSource: undefined,
            dates: undefined,
            dburl: this.sessionState('dburl').get(),
            token: this.sessionState('token').get(),
            messageToken: this.sessionState('messageToken').get(),
        }

        this.fetchDataSource = false;
        this.Loading = <div className={styles.loading}><Watch color='orange' /></div> ;
        this.gridRef = React.createRef();
        this.contentRef = React.createRef();

        this.setAppState = this.setAppState.bind(this);
        this.sessionState = this.sessionState.bind(this);
        this.setSearchState = this.setSearchState.bind(this);

        this.params = {
            Views: Views,
            history: history,            
            contentRef: this.contentRef,
            gridRef: this.gridRef,
            Loading: this.Loading,
            setAppState: this.setAppState,
            sessionState: this.sessionState,
            setSearchState: this.setSearchState,
        }
    }

    setSearchState(text, state) {
        this.setState({searchText: text, ...state});
        if (this.gridRef.current) {
            this.gridRef.current._instance.searchByText(text)
            setTimeout(() => this.gridRef.current._instance.navigateToRow(this.state.date.rowKey), 100)                                        
        }       
    }

    sessionState(key) {
        return {
            set: (value) => {
                sessionStorage.setItem(key, JSON.stringify(value));
                this.setState({[key]: value})
            },
            get: () => {
                try {
                    const keyValue = sessionStorage.getItem(key);
                    const jsonValue = JSON.parse(keyValue);
                    return jsonValue;
                } catch {
                    return undefined;
                }    
            },
            remove: () => {
                sessionStorage.removeItem(key);
                this.setState({[key]: undefined})
            }
        }        
    }

    setAppState(state) {
        this.setState(state)
    }

    render() {
        Notification.requestPermission()

        if (!this.state.messageToken && Notification.permission === "granted") {
            getMessageToken((messageToken) => {this.sessionState('messageToken').set(messageToken)});
            return this.Loading;
        }        
        if (!this.state.dburl) return <PublicLayout> <DbKey setDburl={(dburl) => {this.sessionState('dburl').set(dburl)}}/> </PublicLayout>;
        if (!this.state.token) return <PublicLayout> <Login setToken={(token) => {this.sessionState('token').set(token)}} dburl={this.state.dburl} /> </PublicLayout>;        

        if (!this.fetchDataSource && !this.state.dataSource) {
            this.fetchDataSource = true;
            getDataList(this.state.dburl, this.state.token, this.setAppState);
        } 

        return (            
          <ViewLayout {...this.state} {...this.params} >
              <ViewSelector />
          </ViewLayout>
        );
    }
}

export default App;