import DateArray from '../../Utils/DateArray';
import ListArray from '../../Utils/ListArray';

export default (url, token, callback) => {
    return fetch(`${url}/content`, { //list.php?token=${token}
        method: 'GET',
        // crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
      .then(response => response.json())
      .then(result => {
        let value = {
            dataSource: new ListArray(result),
            dates: new DateArray(result)
        }
        callback(value);
        return value;
      })
      .catch((err) => { 
        console.warn('Network error: ', err);
      });
}