import WorkDate from './WorkDate';

export default class DateArray extends Array {
    constructor (props) {
        super(props);
        props.forEach(prop => {
            this.pushDate(prop.Datum);
        });
    }

    pushDate = (date) => {
        const _date = new WorkDate(date);
        const year = _date.getFullYear();
        const month = _date.getMonth() + 1;
        const day = _date.getDate();
        if (!this[year]) this[year] = {};
        if (!this[year][month]) this[year][month] = [];
        if (!this[year][month].includes(day)) this[year][month].push(day);
    }

    hasDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        if (year in this) {
            if (month in this[year]) {
                if (this[year][month].includes(day)) {
                    return true;
                }
            }
        }                           
        return false;
    }

    hasMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        if (year in this) {
            if (month in this[year]) {
                return true;
            }
        }                           
        return false;
    }

    hasYear = (date) => {
        const year = date.getFullYear();
        if (year in this) {
            return true;
        }                           
        return false;
    }
}