import WorkDate from './WorkDate';

export default class ListArray extends Array {
    constructor (props) {
        super(props);

        props.forEach((prop, i) => {
            prop.Datum = new WorkDate(prop.Datum);
            prop.date = prop.Datum.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'});
            prop.time = prop.Datum.toLocaleTimeString('de-DE');
            this[i] = prop;
        });
    }
}